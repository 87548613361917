import Currency from '@/store/constants/currency';

/**
 * This method finds a currency in a list and returns the symbol for said currency.
 *
 * @param currency
 * @returns {string}
 */
export function getCurrencySymbol(currency) {
  return Currency.list.find((item) => item._id === currency).symbol;
}

/**
 * This method will return a string to be displayed in the payout field.
 *
 * @param payout
 * @param currency
 * @returns {string}
 */
export function getPayoutField(payout, currency) {
  if (payout.model === 'BY_GROUP') return 'Multiple Payouts';
  let costs = [];
  if (payout.costs.length > 0) {
    costs = [payout.costs[0]];
  }
  let currencySymbol = getCurrencySymbol(currency);
  let payoutType;
  let payoutCost;
  switch (payout.type) {
    case 'PER_ACTION':
      payoutType = 'CPA';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value}`;
        })
        .join(',');
      break;
    case 'PER_SALE':
      payoutType = 'CPS';
      payoutCost = costs
        .map((pc) => {
          return `${pc.percentage}%`;
        })
        .join(',');
      break;
    case 'PER_CLICK':
      payoutType = 'CPL';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value}`;
        })
        .join(',');
      break;
    case 'PER_ACTION_AND_PER_SALE':
      payoutType = 'CPA & CPS';
      payoutCost = costs
        .map((pc) => {
          return `${currencySymbol}${pc.value} + ${pc.percentage}%`;
        })
        .join(',');
  }
  return `${payoutType} | ${payoutCost}`;
}
